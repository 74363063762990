<template>
  <div
    class="point-task__wrapper"
    :class="{
      completed: task.completed,
      'completed-multy': task.count > 0 && task.type == 'multy',
    }"
  >
    <span :class="`type ${task.type}`" v-if="!isPC">{{ i18n.t(`tasks.data.${task.type}`) }} <br /></span>
    <div class="point-task__title-wrapper">
      <p class="point-task__title">
        {{ i18n.t(`tasks.titles.${task.key}`) }}
        <span :class="`type ${task.type}`" v-if="isPC">
          {{ i18n.t(`tasks.data.${task.type}`) }}
        </span>
      </p>
      <p class="point-task__earned">
        {{ task.value * task.count }}
      </p>
    </div>
    <p class="point-task__prize">
      {{ task.value + i18n.t(`tasks.data.point`) }}
    </p>
    <p class="point-task__description" v-html="i18n.t(`tasks.description.${task.key}`, { link: links[i18n.locale] })" />
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    task: Object,
  },
  setup() {
    const i18n = reactive(useI18n());

    const links = {
      ru: 'https://forms.gle/H4YpHFMDR9e5XJWn6',
      en: 'https://forms.gle/H4YpHFMDR9e5XJWn6',
    };

    const isPC = window.innerWidth > 500;

    return { i18n, isPC, links };
  },
};
</script>

<style></style>
